'use client';

import { ZENDESK_URL } from '@/shared/constants';
import { useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import React from 'react';

import CheckScribble from '@/components/ui/icons/CheckScribble';
import ErrorOmino from '@/components/ui/icons/ErrorOmino';

import Button from '../Button';

type HeadingStatusProps = {
  title?: string;
  subtitle?: string;
  messageType: 'error' | 'success';
};

const HeadingStatus = ({
  title,
  subtitle,
  messageType,
}: HeadingStatusProps) => {
  const searchParams = useSearchParams();
  const errorId = searchParams.get('errorId');
  const successType = searchParams.get('message');

  const t = useTranslations('heading.status');

  let specificTitle;
  let specificMessage;

  if (title && subtitle) {
    specificTitle = title;
    specificMessage = subtitle;
  } else if (messageType === 'success') {
    switch (successType) {
      case 'reactivate-account-complete': {
        specificTitle = t('success.reactivate-account-complete.title');
        specificMessage = t('success.reactivate-account-complete.subtitle');
        break;
      }
      case 'reactivate-account-email-sent': {
        specificTitle = t('success.reactivate-account-email-sent.title');
        specificMessage = t('success.reactivate-account-email-sent.subtitle');
        break;
      }
      case 'recovery-email-sent': {
        specificTitle = t('success.email-sent.title');
        specificMessage = t('success.email-sent.subtitle');
        break;
      }
      case 'weak-password': {
        specificTitle = t('success.weak-password.title');
        specificMessage = t('success.weak-password.subtitle');
        break;
      }
      case 'password-update': {
        specificTitle = t('success.password-updated.title');
        specificMessage = t('success.password-updated.subtitle');
        break;
      }
      case null:
      default: {
        specificTitle = t('success.title');
        specificMessage = t('success.subtitle');
        break;
      }
    }
  } else {
    switch (errorId) {
      case 'reactivate-account-error': {
        specificTitle = t('error.reactivate-account-error.title');
        specificMessage = t('error.reactivate-account-error.subtitle');
        break;
      }
      case 'too-many-attempts': {
        specificTitle = t('error.too-many-attempts.title');
        specificMessage = t('error.too-many-attempts.subtitle');
        break;
      }
      case 'invalid-url': {
        specificTitle = t('error.invalid-url.title');
        specificMessage = t('error.invalid-url.subtitle');
        break;
      }
      case 'reload-page': {
        specificTitle = t('error.reload-page.title');
        specificMessage = t('error.reload-page.subtitle');
        break;
      }
      case null:
      default: {
        specificTitle = t('error.title');
        specificMessage = t('error.subtitle');
        break;
      }
    }
  }

  const showErrorId =
    errorId &&
    specificTitle === t('error.title') &&
    specificMessage === t('error.subtitle');

  const renderButton = () => {
    if (messageType === 'error' && errorId === 'too-many-attempts') {
      return (
        <Button link="/login" className="mt-10" buttonStyle="primary">
          {t('button-label-login')}
        </Button>
      );
    }

    if (
      messageType === 'success' &&
      ['password-update', 'reactivate-account-complete'].includes(
        successType || ''
      )
    ) {
      return (
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          className="mt-10"
          buttonStyle="primary"
        >
          {t('button-label-login')}
        </Button>
      );
    }

    if (messageType !== 'success') {
      return (
        <Button link={ZENDESK_URL} className="mt-10" buttonStyle="primary">
          {t('button-label-support')}
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <div className="flex flex-col items-center space-y-2">
        {messageType === 'success' ? (
          <CheckScribble className="h-[150px] w-[150px]" />
        ) : (
          <ErrorOmino className="h-[150px] w-[150px]" />
        )}
        <h1 className="text-sb32 text-grey-15">{specificTitle}</h1>
        <p className="text-r14 text-grey-15">
          {specificMessage}
          {showErrorId && (
            <>
              <br />
              <span className="text-r14 text-grey-15">
                {t('error.id-label')}:{' '}
                <span className="font-medium text-secondary-blue-400">
                  {errorId}
                </span>
              </span>
            </>
          )}
        </p>
      </div>
      {renderButton()}
    </>
  );
};

export default HeadingStatus;
